import axios from "../axiosConfig";
const api_url = 'https://abegarageapi.nati16.com';


// A function to send post request to create a new employee
async function createEmploye(formData, loggedInEmployeeToken) {
  // define your headers
  const headers = {
    "x-access-token": loggedInEmployeeToken,
  };

  const data = await axios.post("/api/employee", formData, { headers });

  return data;
}

// A function to send get request to get all employees
async function getAllEmployees(token) {
  const headers = {
    "x-access-token": token,
  };

  const data = await axios.get("/api/employees", { headers });

  return data;
}

// A function to employee update request
async function updateEmployee(formData, loggedInEmployeeToken) {
  const headers = {
    "x-access-token": loggedInEmployeeToken,
  };

  const data = await axios.put("/api/employee/update", formData, { headers });

  // console.log(data);

  return data;
}

// a function to get single employee
// a function to get single employee
async function singleEmployee(employee_id, loggedInEmployeeToken) {
  const headers = {
    "x-access-token": loggedInEmployeeToken,
  };

  try {
    const data = await axios.get(`/api/employee/single/${employee_id}`, { headers });
    return data;
  } catch (error) {
    console.error("Error fetching employee data:", error);
    throw error;  // Rethrow error to handle it in the calling function
  }
}


const employeeService = {
  createEmploye,
  getAllEmployees,
  updateEmployee,
  singleEmployee,
};

export default employeeService;
