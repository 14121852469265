import React from "react";
import Contactes from "../../components/StatePages/Contactes/Contactes";
import AboutUs3 from "../../components/StatePages/About/AboutUs3";
function Contact() {
  return (
    <>
      <Contactes />
      <AboutUs3 />
    </>
  );
}

export default Contact;
