import React from "react";

// Import the login form
import LoginForm from "../../components/LoginForm/LoginForm";
function Login() {
  return (
    <div>
      <LoginForm />
    </div>
  );
}

export default Login;
