import axios from "../axiosConfig";
const api_url = 'https://abegarageapi.nati16.com';
async function addOrder(formData, loggedInEmployeeToken) {
  console.log(formData);

  const headers = {
    "x-access-token": loggedInEmployeeToken,
  };

  const data = await axios.post("/api/order", formData, { headers });

  return data;
}


async function getAllOrder(loggedInEmployeeToken) {
  const headers = {
    "x-access-token": loggedInEmployeeToken,
  };

  const data = await axios.get("api/orders", { headers });

  // console.log(data)

  return data;
}

async function getSingleOrder(formData) {
  const data = await axios.get(`api/order/single/${formData}`);

  // console.log(data)

  return data;
}

async function updateOrder(formData, loggedInEmployeeToken) {
  console.log(formData);

  const headers = {
    "x-access-token": loggedInEmployeeToken,
  };

  const data = await axios.put("api/order/update", formData, { headers });

  console.log(data)

  // return data;
}


const getOrderById = async (order_id, userToken) => {
	const requestOption = {
		method: "GET",
		headers: {
			"Content-Type": "application/json",
			// "x-access-token": userToken,
		},
	};
	const response = await fetch(
		`${api_url}/api/order/` + order_id,
		requestOption
	);
	console.log(response);
	return response;
};

//  a function totrack a single employee
const trackOrder = async (order_id) => {
	const requestOption = {
		method: "GET",
		headers: {
			"Content-Type": "application/json",
			// "x-access-token": userToken,
		},
	};
	const response = await fetch(
		`${api_url}/api/orders/` + order_id,   
		requestOption
	);
	console.log(response);
	return response;
};
const Order = {
  addOrder,
  getAllOrder,
  getSingleOrder,
  updateOrder,
  trackOrder,
  getOrderById
};

export default Order;
