import React from 'react';
// Import the AddEmployeeForm component 
import AddEmployeeForm from '../../components/admin/AddEmployeeForm/AddEmployeeForm';
import AdminMenu from '../../components/admin/AdminMenu/AdminMenu';


// Import the AdminMenu component 


function AddEmployee(props) {
  return (
    <div>
      <div className="container-fluid admin-pages">
        <div className="row">
          <div className="col-md-3 admin-left-side">
            <AdminMenu />
          </div>
          <div className="col-md-9 admin-right-side">
            <AddEmployeeForm />
          </div>
        </div>
      </div>
    </div>
  );
}

export default AddEmployee;