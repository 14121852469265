import React from "react";
import "./NotFound.css";

function NotFound() {
	return (
		<div>
			<h1 className="notfound">Page not found</h1>
		</div>
	);
}

export default NotFound;
