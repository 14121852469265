import React from "react";
import  MdOutlineEmail from "@mui/icons-material/Email";
import  CiLocationOn  from "@mui/icons-material/LocationOn";
import  FaPhoneAlt  from "@mui/icons-material/PhoneInTalk";
function Contactes() {
  return (
    <section className="about-section">
      <div className="auto-container">
        <div className="row">
          <div className="col-lg-6">
            <div className="image-box">
              <iframe
                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d628.1015741777554!2d39.53090029830991!3d9.67055654460683!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x1649bd98a70be815%3A0xf607fe72734ef36d!2sDebre%20Birhan%2C%20Ethiopia!5e0!3m2!1sen!2sus!4v1727021136172!5m2!1sen!2sus" 
                width="600"
                height="450"
                style={{ border: "0" }}
                allowFullScreen=""
                loading="lazy"
                referrerPolicy="no-referrer-when-downgrade"
              ></iframe>
                          </div>
          </div>
          <div className="col-lg-6 pl-lg-5">
            <div className="sec-title">
              <h2>Our Address</h2>
              <h4>
                Completely synergize resource Professionally cultivate
                one-to-one customer service.
              </h4>
            </div>

            <div className="text">
              {" "}
              <CiLocationOn color="red" />
              Address:
              <p>DebireBirhan,Ethipia</p>
              <MdOutlineEmail color="red" />
              Email:
              <p>Zengarage@gmail.com</p>
              <FaPhoneAlt color="red" />
              <p>+ 1800 456 7890</p>
              <p>+ 2519 2026 9570</p>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
export default Contactes;
