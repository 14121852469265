import React, { useRef, useState, useEffect } from "react";
import employeeService from "../../../../../services/employee.service";
import { useParams, useNavigate } from "react-router-dom";
import { useAuth } from "../../../../../Context/AuthContext";
import BeatLoader from "react-spinners/BeatLoader"; // Assuming you are using BeatLoader

function EditEmployee() {
  const navigate = useNavigate();
  const [employee_first_name, setFirstName] = useState("");
  const [employee_last_name, setLastName] = useState("");
  const [employee_phone, setPhoneNumber] = useState("");
  const [company_role_id, setCompany_role_id] = useState(1);
  const [active_employee, setActiveEmployee] = useState(false);
  const [employee1, setEmployee1] = useState({});
  const [apiError, setApiError] = useState(false);
  const [apiErrorMessage, setApiErrorMessage] = useState("");
  const [isSubmitting, setIsSubmitting] = useState(false);

  const { employee_id } = useParams();
  const { employee } = useAuth();
  const loggedInEmployeeToken = employee?.employee_token || "";

  const firstNameDom = useRef();
  const lastNameDom = useRef();
  const phoneNumberDom = useRef();
  const companyRoleIdDom = useRef();
  const checkboxDOM = useRef();

  useEffect(() => {
    const fetchData = async () => {
      try {
        const data = await employeeService?.singleEmployee(employee_id, loggedInEmployeeToken);

        if (data?.statusText !== "OK") {
          setApiError(true);
          setApiErrorMessage(
            data?.status === 403
              ? "Please login again"
              : data?.status === 401
              ? "You are not authorized to view this page"
              : "Please try again later"
          );
          return;
        }

        const employee = data?.data?.singleEmployee?.[0];

        if (employee) {
          setFirstName(employee.employee_first_name);
          setLastName(employee.employee_last_name);
          setPhoneNumber(employee.employee_phone);
          setCompany_role_id(employee.company_role_id);
          setEmployee1(employee);
          checkboxDOM.current.checked = employee.active_employee;
          setActiveEmployee(employee.active_employee);
        } else {
          setApiError(true);
          setApiErrorMessage("Employee data not found.");
        }
      } catch (error) {
        console.error(error);
        setApiError(true);
        setApiErrorMessage("An unexpected error occurred. Please try again.");
      }
    };

    fetchData();
  }, [employee_id, loggedInEmployeeToken]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsSubmitting(true);

    const formData = {
      employee_first_name,
      employee_last_name,
      employee_phone,
      company_role_id,
      active_employee,
      employee_id,
    };

    try {
      await employeeService.updateEmployee(formData, loggedInEmployeeToken);
      navigate("/admin/employees");
    } catch (error) {
      console.error(error);
      setApiErrorMessage("Failed to update employee. Please try again.");
    } finally {
      setIsSubmitting(false);
    }
  };

  const handleCheckboxChange = () => {
    setActiveEmployee(checkboxDOM.current.checked);
  };

  return (
    <>
      <section className="contact-section">
        <div className="auto-container">
          <div className="contact-title">
            <h2>Update: {employee1.employee_email} </h2>
          </div>
          <div className="row clearfix">
            <div className="form-column col-lg-7">
              <div className="inner-column">
                <div className="contact-form">
                  <form onSubmit={handleSubmit}>
                    <div className="row clearfix">
                      {/* First Name */}
                      <div className="form-group col-md-12">
                        <input
                          type="text"
                          name="employee_first_name"
                          placeholder="Employee first name"
                          ref={firstNameDom}
                          value={employee_first_name}
                          onChange={() => setFirstName(firstNameDom.current.value)}
                          required
                        />
                      </div>

                      {/* Last Name */}
                      <div className="form-group col-md-12">
                        <input
                          type="text"
                          name="employee_last_name"
                          placeholder="Employee last name"
                          required
                          ref={lastNameDom}
                          value={employee_last_name}
                          onChange={() => setLastName(lastNameDom.current.value)}
                        />
                      </div>

                      {/* Phone Number */}
                      <div className="form-group col-md-12">
                        <input
                          type="text"
                          name="employee_phone"
                          placeholder="Employee phone (555-555-5555)"
                          ref={phoneNumberDom}
                          required
                          value={employee_phone}
                          onChange={() => setPhoneNumber(phoneNumberDom.current.value)}
                        />
                      </div>

                      {/* Employee Role */}
                      <div className="form-group col-md-12">
                        <select
                          name="employee_role"
                          className="custom-select-box"
                          ref={companyRoleIdDom}
                          value={company_role_id}
                          onChange={() => setCompany_role_id(companyRoleIdDom.current.value)}
                          required
                        >
                          <option value="1">Employee</option>
                          <option value="2">Manager</option>
                          <option value="3">Admin</option>
                        </select>
                      </div>

                      {/* Active Employee */}
                      <div className="form-group col-md-12 form-control">
                        <h5>Active Employee</h5>
                        <input
                          type="checkbox"
                          ref={checkboxDOM}
                          checked={active_employee}
                          onChange={handleCheckboxChange}
                        />
                      </div>

                      {/* Submit Button */}
                      <div className="form-group col-md-12">
                        <button className="theme-btn btn-style-one" type="submit" disabled={isSubmitting}>
                          <span>
                            {isSubmitting ? <BeatLoader color="white" size={8} /> : "Update Employee"}
                          </span>
                        </button>
                        {apiError && (
                          <div className="validation-error" style={{ color: "red" }} role="alert">
                            {apiErrorMessage}
                          </div>
                        )}
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default EditEmployee;
