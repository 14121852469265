import React from "react";

// Import the Routes and Route components from react-router
import { Routes, Route } from "react-router";

// Import the page components
import Home from './markup/pages/StaticsPage/Home'
import About from './markup/pages/StaticsPage/About';
import Contact from './markup/pages/StaticsPage/Contact';
import Service from "./markup/pages/StaticsPage/Services";
import Login from './markup/pages/StaticsPage/Login'
import NotFound from "./markup/components/NotFound/NotFound";
import Unauthorized from './markup/pages/StaticsPage/Unauthorized';
import AddEmployee from './markup/pages/admin/AddEmployee'
import Employees from './markup/pages/admin/Employees';
import EditEmployee from "./markup/pages/admin/Employees/EditEmployee.js";
import AddCustomer from "./markup/pages/admin/Customers/AddCustomer";
import Customers from "./markup/pages/admin/Customers/Customers";
import EditCustomer from "./markup/pages/admin/Customers/EditCustomer";
import Admin from "./markup/pages/admin/Admin/Admin";
import NewOrder from "./markup/pages/admin/Orders/NewOrder";
import CustomerProfile from "./markup/pages/admin/Orders/CustomerProfile";
import Services from './markup/pages/admin/Service/Services';

import EditService from "./markup/components/admin/Services/EditServices/EditServices";
import Orders from "./markup/pages/admin/Orders/Orders";
import EditOrder from "./markup/pages/admin/Orders/EditOrders";
import OrderDetails from "./markup/pages/admin/Orders/OrderDetails";
import AddNewOrders from "./markup/pages/admin/Orders/AddNewOrders";
import CreateNewOrders from "./markup/pages/admin/Orders/CreateNewOrders";
import AdminDashboard from "./markup/pages/admin/AdminDashboardPage";
import EditVihicle from "./markup/pages/admin/Orders/EditVihicle";

// Import the header and footer components
import Header from "./markup/components/Header/Header";
import Footer from "./markup/components/Footer/Footer";

// import the css files
import "./assets/template_assets/css/bootstrap.css";
import "./assets/template_assets/css/style.css";
import "./assets/template_assets/css/responsive.css";
import "./assets/template_assets/css/color.css";

// import the custom files
import "./assets/styles/custom.css";

// import the PrivateAuthToute component
import PrivateAuthRoute from './markup/components/Auth/PrivateAuthRoute';


function App() {
  return (
    <>
    <Header />
    <Routes>
      {/* home page route */}
      <Route path="/" element={<Home />} />

      <Route path="/about" element={<About />} />
      <Route path="/service" element={<Service />} />
      <Route path="/contact" element={<Contact />} />
      <Route path="*" element={<NotFound />} />
      {/* login page route */}
      <Route path="/login" element={<Login />} />

      {/* unauthorized page route */}
      <Route path="/unauthorized" element={<Unauthorized />} />

      {/* Dashboard page route */}
      <Route
        path="/admin"
        element={
          <PrivateAuthRoute roles={[1, 2, 3]}>
            <AdminDashboard />{" "}
          </PrivateAuthRoute>
        }
      />

      {/* Customers page route */}
      <Route
        path="/admin/customers"
        element={
          <PrivateAuthRoute roles={[2, 3]}>
            <Customers />
          </PrivateAuthRoute>
        }
      />

      {/* add customer page route */}
      <Route path="/admin/add-customer" element={<AddCustomer />} />

      {/* Edit Customer Page Route */}
      <Route
        path="/admin/customer-update/:customer_hash"
        element={<EditCustomer />}
      />

      {/* Customer Profile Page Route */}
      <Route
        path="/admin/customer-profile/:customer_hash"
        element={<CustomerProfile />}
      />

      {/* New Order Page Route */}
      <Route path="/admin/services" element={<Services />} />

      {/* Customer Profile Page Route */}
      <Route
        path="/admin/services/service-update/:service_id"
        element={<EditService />}
      />

      {/* New Order Page Route */}
      <Route path="/admin/order" element={<NewOrder />} />

      <Route
        path="/admin/orders"
        element={
          <PrivateAuthRoute roles={[1, 2, 3]}>
            <Orders />
          </PrivateAuthRoute>
        }
      />

      <Route
        path="admin/orders/order-update/:order_hash"
        element={
          <PrivateAuthRoute roles={[1, 2, 3]}>
            <EditOrder />
          </PrivateAuthRoute>
        }
      />

      <Route
        path="/orders/order-detail/:order_hash"
        element={<OrderDetails />}
      />

      <Route
        path="admin/order/add-new-order/:customer_hash"
        element={
          <PrivateAuthRoute roles={[1, 2, 3]}>
            <AddNewOrders />
          </PrivateAuthRoute>
        }
      />

<Route
          path="admin/order/add-new-order/select-service/:customer_hash/:vehicle_id"
          element={
            <PrivateAuthRoute roles={[1, 2, 3]}>
              <CreateNewOrders />
            </PrivateAuthRoute>
          }
        />
      {/* Edit Vehicle */}
      <Route
        path="/admin/vehicle/edit/:vehicle_id"
        element={<EditVihicle />}
      />

      {/* Employees page route */}
      <Route path="/admin/employees" element={<Employees />} />
      {/* admin page route */}
      <Route path="/admin" element={<Admin />} />
      {/* Edit Employees page route */}
      <Route
        path="/admin/employee-update/:employee_id"
        element={<EditEmployee />}
      />
      {/* add employee page route */}
      <Route path="/admin/add-employee" element={<AddEmployee />} />
    </Routes>
    <Footer />
  </>
  );
}
export default App;






// import React from 'react';
// import {Routes, Route} from "react-router";
// import './App.css';
// // import the css file
// import "./assets/template_assets/css/bootstrap.css"
// import "./assets/template_assets/css/style.css"
// import "./assets/styles/custom.css"
// import "./assets/template_assets/css/responsive.css"
// import "./assets/template_assets/css/color.css"
// // import the custom css
// import Home from './markup/pages/Home'
// import Login from './markup/pages/Login'
// import Unauthorized from './markup/pages/Unauthorized';
// // import Orders from './markup/pages/admin/Orders';
// // import Customers from './markup/pages/admin/Customers';
// import Employees from './markup/pages/admin/Employees';
// import AddCustomerPage from "./markup/pages/admin/AddCustomerPage";
// // import EditCustomerPage from "./markup/pages/admin/EditCustomerPage";
// import AllCustomers from './markup/components/AllCustomers/AllCustomers';
// import CustomerProfilePage from './markup/pages/admin/CustomerProfile';
// import AddEmployee from './markup/pages/admin/AddEmployee'
// import Header from './markup/components/Header/Header';
// import Footer from './markup/components/Footer/Footer';
// // Import the PrivateAuthRoute component 
// import PrivateAuthRoute from './markup/components/Auth/PrivateAuthRoute';
// import SingleServicePage from './markup/pages/admin/SingleServicePage';
// import ServicePage from './markup/pages/admin/ServicePage';
// // import the css file
// import "./assets/template_assets/css/bootstrap.css"
// import "./assets/template_assets/css/style.css"
// import "./assets/template_assets/css/responsive.css"
// import "./assets/template_assets/css/color.css"
// // import the custom css
// import "./assets/styles/custom.css"
// import AdminDashboardPage from './markup/pages/admin/AdminDashboardPage';
// // import AddOrderPage from './markup/pages/admin/AddOrderPage';
// // import OrderForEmployeePage from './markup/pages/OrderForEmployeePage';
// // import TrackOrder from './markup/components/admin/TrackOrder/TrackOrder';
// // import OrdersPage from './markup/pages/admin/OrdersPage';
// import About from './markup/pages/About';
// import Contact from './markup/pages/Contact';
// import Services from './markup/pages/Services';





// function App() {
//   return (
//     <>
//       <Header />
//       <Routes>
//         <Route path="/" element={<Home />} />
//         <Route path="/login" element={<Login />} />
//         <Route path="/unauthorized" element={<Unauthorized />} />
//         {/* // Add the Orders Route  */}
        
//         {/* <Route path="/admin/orders"
//           element={
//             <PrivateAuthRoute roles={[1, 2, 3]}>
//               <Orders />
//             </PrivateAuthRoute>
//           } /> */}
//         {/* // Add the Customers Route  */}
//         <Route path="/admin/customers"
//           element={
//             <PrivateAuthRoute roles={[2, 3]}>
//               <AllCustomers />
//             </PrivateAuthRoute>
//           } />
//         {/* // Add the Employees Route  */}
//         <Route path="/admin/employees" element={<Employees />} />
//         <Route path="/admin/add-customer" element={<AddCustomerPage />} />

// 				{/* <Route path="/admin/customer/edit/:id" element={<EditCustomerPage />} /> */}

// 				<Route path="/admin/customer/:id" element={<CustomerProfilePage />} />
//         <Route path="/admin" element={<AdminDashboardPage />} />

//         <Route path="/admin/services" element={<ServicePage />} />

// <Route path="/admin/service/:id" element={<SingleServicePage />} />
// {/* 
// <Route path="/admin/order" element={<AddOrderPage />} />
// 				<Route path="/:order_hash" element={<TrackOrder />} /> */}

// 				{/* <Route path="*" element={<notfound/>} /> */}
// 				<Route path="/services" element={<Services />} />
// 				<Route path="/about" element={<About />} />
// 				<Route path="/contact" element={<Contact />} />

// 				{/* <Route path="/employee/:id" element={<OrderForEmployeePage />} />

// 				<Route path="/admin/order" element={<AddOrderPage />} />
// 				<Route path="/admin/orders" element={<OrdersPage />} /> */}
//         <Route path="/admin/add-employee"
//           element={
//             <PrivateAuthRoute roles={[3]}>
//               <AddEmployee /> 
//             </PrivateAuthRoute>
//           } />
// {/*         
//           Customers (/admin/customers) - managers and admins
//           Orders (/admin/orders) - Can be accessed by all employees
//           Add employee (/admin/add-employee) - admins only 
//             - Admin: 3 
//             - Manager: 2 
//             - Employee: 1 
//         */}
//       </Routes>
//       <Footer />
//     </>
//   );
// }

// export default App;


























