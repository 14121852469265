import React from "react";

// import the auth hook context
import { useAuth } from "../../../../Context/AuthContext";

// import the login component
import LoginForm from "../../../components/LoginForm/LoginForm";

// import the admin menu component

import OrdersList from "../../../components/admin/Orders/OrdersList/OrdersList";
import AdminMenu from "../../../components/admin/AdminMenu/AdminMenu";



function Orders() {
   const { isLogged, isAdmin, isAdmin_manager } = useAuth();

  if (isLogged ) {

    console.log("Amanuel");

    if (isAdmin_manager || isAdmin) {
      return (
        <div>
          <div className="container-fluid admin-pages">
            <div className="row">
              <div className="col-md-3 admin-left-side">
                <AdminMenu />
              </div>
              <div className="col-md-9 admin-right-side">
                <OrdersList />
              </div>
            </div>
          </div>
        </div>
      );
    } else {
      return (
        <div>
          <h1 style={{ padding: "100px" }}>
            You don't have the Permission to access the page you request!
          </h1>
        </div>
      );
    }
  } else {
    return (
      <div>
        <LoginForm />
      </div>
    );
  }
}

export default Orders;
