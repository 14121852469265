import React from "react";
import AdminDashboard from "../../components/AdminDashboard/AdminDashboard";
import AdminMenu from "../../components/admin/AdminMenu/AdminMenu";
import getAuth from "../../../utils/auth";
import { useNavigate } from "react-router-dom";

function AdminDashboardPage() {
	const navigate = useNavigate();

	const decodeTokenData = getAuth().then((response) => {
		// console.log(response);
		// console.log(response.employee_role);
		if (!response.employee_token) {
			navigate("/login");
		} else if (response.employee_role !== 3) {
			// console.log("its ", response.employee_role);
			navigate("/unauthorized");
		}
	});

	return (
		<div>
			<div className="container-fluid admin-pages">
				<div className="row">
					<div className="col-md-3 admin-left-side">
						<AdminMenu />
					</div>
					<div className="col-md-9 admin-right-side">
						<AdminDashboard />
					</div>
				</div>
			</div>
		</div>
	);
}

export default AdminDashboardPage;
