import React from "react";

function Unauthorized() {
  return (
    <div>
      <h1 style={{ padding: "100px" }}>
        You don't have the Permission to access the page you request!
      </h1>
    </div>
  );
}

export default Unauthorized;
