import React from 'react'
import Service from '../../components/StatePages/Service/Service'
import Service2 from '../../components/StatePages/Service/Service2'

function Services() {
  return (
    <div>
         <Service/>
         <Service2/>
    </div>
  )
}

export default Services