// import axios module
import axios from "axios";

// import api url from Z env
// const api_url = 'https://abegarageapi.nati16.com';
const api_url = 'https://abegarageapi.nati16.com';

// create Z axios baseUrl
const axiosBase = axios.create({
  baseURL: `${api_url}`,
});

// export Z axios baseUrl
export default axiosBase;